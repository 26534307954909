(function() {
	'use strict';

	angular.module('wamasysApp').controller('CollectionListeDetailController',
			CollectionListeDetailController);

	CollectionListeDetailController.$inject = [ '$scope', '$filter',
			'$rootScope', '$stateParams', 'CollectionListe',
			'PortefeuilleContrat' ];

	function CollectionListeDetailController($scope, $filter, $rootScope,
			$stateParams, CollectionListe, PortefeuilleContrat) {
		var vm = this;
		vm.disableBtnSaveCancel =false;
		vm.disableBtnEdition =false;
		vm.collectionListe = {};
		vm.errorValidation = false;
		vm.messageError ="";
		vm.load = function(id) {
			CollectionListe.get({
				id : id
			}, function(result) {
				vm.collectionListe = result;
				$rootScope.$broadcast('updateLigneCollectionListe', result);
			});
		};
		if ($stateParams.id) {
			vm.load($stateParams.id);
		}
		var unsubscribe = $rootScope.$on('wamasysApp:collectionListeUpdate',
				function(event, result) {
					vm.collectionListe = result;
					vm.majTotalMontant(vm.collectionListe);
				});
		$scope.$on('$destroy', unsubscribe);

		 //Boite de dialog d'attente.
		var $dialog = $(
				'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:15%; overflow-y:visible;">' +
				'<div class="modal-dialog modal-m">' +
				'<div class="modal-content">' +
					'<div class="modal-header"><h3 style="margin:0;"></h3></div>' +
					'<div class="modal-body">' +
						'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
					'</div>' +
				'</div></div></div>');

		var options = {};
		var message = 'Please wait ! Action in progress.';
		var settings = $.extend({
			dialogSize: 'm',
			progressType: '',
			onHide: null // This callback runs after the dialog was hidden
		}, options);
		$dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
		$dialog.find('.progress-bar').attr('class', 'progress-bar');
		$dialog.find('h3').text(message);
		
		
		
		vm.totalMontantACollecter = 0;
		vm.totalMontantCollecte = 0;
		vm.totalMontantCollecteAvecCorrection = 0;
//		vm.totalMontantCollecteEnAgence = 0;
		vm.totalMontantACollecterSansMoneyBox = 0;
		vm.totalMontantNonCollecte = 0;

		vm.majTotalMontant = function(data) {

			vm.totalMontantACollecter = 0;
			vm.totalMontantCollecte = 0;
			vm.totalMontantCollecteAvecCorrection = 0;
//			vm.totalMontantCollecteEnAgence = 0;
			vm.totalMontantACollecterSansMoneyBox = 0;
			vm.totalMontantNonCollecte = 0;

			if (data && data.contratCollectionLists) {
				for (var i = 0; i < data.contratCollectionLists.length; i++) {

					if (data.contratCollectionLists[i].lignePaiementService) {

						for (var x = 0; x < data.contratCollectionLists[i].lignePaiementService.length; x++) {
							vm.totalMontantACollecter += (parseFloat(data.contratCollectionLists[i].lignePaiementService[x].montantAPayerTtc) - parseFloat(data.contratCollectionLists[i].lignePaiementService[x].moneyboxAvantPaiement));
							vm.totalMontantACollecterSansMoneyBox += data.contratCollectionLists[i].lignePaiementService[x].montantAPayerTtc;
							vm.totalMontantCollecte += data.contratCollectionLists[i].lignePaiementService[x].montantPayeCollecteur;

						}
					}
				}
			}

			vm.totalMontantCollecteAvecCorrection += vm.totalMontantCollecte;
			
			if (data && data.paymentCorrectionList) {
				for (var i = 0; i < data.paymentCorrectionList.length; i++) {

					vm.totalMontantCollecteAvecCorrection += parseFloat(data.paymentCorrectionList[i].montantTtc);
				
				}
			}
			
			vm.totalMontantNonCollecte = parseFloat(vm.totalMontantACollecter)	- parseFloat(vm.totalMontantCollecteAvecCorrection);

			vm.totalMontantCollecteAvecCorrection = Number((vm.totalMontantCollecteAvecCorrection).toFixed(2));

			vm.totalMontantACollecter = Number((vm.totalMontantACollecter)
					.toFixed(2));
			vm.totalMontantCollecte = Number((vm.totalMontantCollecte)
					.toFixed(2));
			
			vm.totalMontantACollecterSansMoneyBox = Number((vm.totalMontantACollecterSansMoneyBox)
					.toFixed(2));
			vm.totalMontantNonCollecte = Number((vm.totalMontantNonCollecte)
					.toFixed(2));

		}

		$scope.$on('updateLigneCollectionListe', function(event, data) {
			vm.majTotalMontant(data);
			vm.errorValidation = controleAvantValidation();
		});

		vm.valider = function() {
// TODO Controle annuler car sinon ça bloque sur les lignes invisibles (où le mojtant à payer est à 0) 
// et ça bloque sur les lignes où on a un seul installment.
//			if( ! controleAvantValidation()){
//				return ;
//			}
			
			vm.disableBtnEdition =true;
			
			
			$dialog.modal();
			
			CollectionListe.valider({
				id : vm.collectionListe.id
			}, function(result) {
				vm.disableBtnEdition =false;
				$scope.$emit('wamasysApp:collectionListeUpdate', result);
				vm.isSaving = false;
				$dialog.modal('hide');
			},function(error) {
				$dialog.modal('hide');
			}
			);
		}
		
		var controleAvantValidation = function(){
			vm.messageError = "";
			var errorValidation = false;
			
			if (vm.collectionListe && vm.collectionListe.contratCollectionLists) {
				for (var i = 0; i < vm.collectionListe.contratCollectionLists.length; i++) {

					if (vm.collectionListe.contratCollectionLists[i].lignePaiementService) {

						for (var x = 0; x < vm.collectionListe.contratCollectionLists[i].lignePaiementService.length; x++) {

							// vm.collectionListe.contratCollectionLists[i].contrat.referenceContrat


							if ("A_PAYER_AVEC_ECHELONNEMENT" == vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].ligneContrat.serviceVendu.typePaiement) {
								
								//On teste que le montant payé n'est pas supérieur au reste à papyer sur les services de type échelonnement.
								
								var montantBalanceCf = parseFloat(vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].montantTotalAPayer)
										- parseFloat(vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].montantTotalDejaPaye);

								var montantPayePourCetteCL = 0;

								if(vm.collectionListe.etat != 'VALIDE' ){
									if (vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].montantPayeCollecteur) {
										 montantPayePourCetteCL = montantPayePourCetteCL 
										 	+ vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].montantPayeCollecteur
									}
								}
								
								
								montantPayePourCetteCL =  Number(montantPayePourCetteCL.toFixed(2)) ;
								montantBalanceCf = Number(montantBalanceCf.toFixed(2));
								
								if ( montantPayePourCetteCL > montantBalanceCf ) {
									
									   
									     
									vm.messageError = vm.messageError 
									+ '<li>Error, the amount paid is higher than the total amount to be paid on : '
											+vm.collectionListe.contratCollectionLists[i].contrat.referenceContrat
											+' for service : '
											+vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].ligneContrat.serviceVendu.libelle
											+'<br/> '
											+' Paid amount : ' +montantPayePourCetteCL
											+' Remaining amount to be paid : '+montantBalanceCf
											+'.You should assign the excess amount to another service or apply a regulation. '
											+'</li>';
										
									errorValidation = true;
								}

							

							}
						}
					}
				}
				return errorValidation;
			}
		}

		vm.startEdit = function() {
			vm.disableBtnEdition =true;
			vm.disableBtnSaveCancel =false;
			jQuery("#btnSaveRows,#btnCancelRows").show();

			var grid = $("table[id^=jqgrid-table]");
			var ids = grid.jqGrid('getDataIDs');

			for (var i = 0; i < ids.length; i++) {
				grid.jqGrid('editRow', ids[i]);
			}

		};
		

		
		vm.print = function() {
			var printContents = document.getElementById('full-screen-widget').innerHTML;

			var content = '<strong>'
					+ $filter('translate')
							(
									'wamasysApp.collectionListe.titreListeCollecteImpression')
					+ ' '
					+ $filter('date')(vm.collectionListe.dateFinPeriode,
							"mediumDate")
					+ '</strong> '
					+ ' ID : '
					+ vm.collectionListe.referenceCollectionList
					+ ' '


					+ $filter('translate')(
							'wamasysApp.collectionListe.collecteur') + ' :  '
					+ vm.collectionListe.collecteur.firstName + ' '
					+ vm.collectionListe.collecteur.lastName + '<br>';

			content += '<table style=" ">';
			content += '<tr><td>'
					+ $filter('translate')(
							'wamasysApp.contrat.referenceContrat')
					+ '</td><td>'
					+ $filter('translate')('wamasysApp.contrat.nomPersonne')
					+ '</td><td>service</td><td>'
					+ $filter('translate')('wamasysApp.contrat.type')
					+ '</td><td>'
					+ $filter('translate')(
							'wamasysApp.contrat.dernierReleveCompteur')
					+ '</td><td>'
					+ $filter('translate')(
							'wamasysApp.collectionListe.quantity')
					+ '</td><td>'
					+ $filter('translate')(
							'wamasysApp.collectionListe.montantAPayer')
					+ '</td><td>'
					+ $filter('translate')(
							'wamasysApp.collectionListe.moneyBoxAvantPaiement')
					+ '</td>'
					+ '<td>'
					+ $filter('translate')(
							'wamasysApp.collectionListe.montantTotalAPayer')
					+ '</td>'
					+ '<td style="width:60px;"></td><td style="width:60px;"></td><td style="width:60px;">'
					+ $filter('translate')(
							'wamasysApp.collectionListe.signature')
					+ '</td></tr>';

			if (vm.collectionListe && vm.collectionListe.contratCollectionLists) {
				for (var i = 0; i < vm.collectionListe.contratCollectionLists.length; i++) {

					if (vm.collectionListe.contratCollectionLists[i].lignePaiementService) {

						for (var x = 0; x < vm.collectionListe.contratCollectionLists[i].lignePaiementService.length; x++) {

							content += '<tr>'
									+ '<td>'
									+ vm.collectionListe.contratCollectionLists[i].contrat.referenceContrat
									+ '</td><td>'
									+ vm.collectionListe.contratCollectionLists[i].contrat.nomPersonne;

							if ("A_PAYER_AVEC_ECHELONNEMENT" == vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].ligneContrat.serviceVendu.typePaiement) {
								var montantBalanceCf = parseFloat(vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].montantTotalAPayer)
										- parseFloat(vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].montantTotalDejaPaye);

								if (vm.collectionListe.etat == "VALIDE") {

									if (vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].montantPayeCollecteur) {
										montantBalanceCf = parseFloat(montantBalanceCf)
												- parseFloat(vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].montantPayeCollecteur);
									}
								}

								content += '</td><td>'
										+ vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].ligneContrat.serviceVendu.libelle
										+ " bal: " + montantBalanceCf;

							} else {
								content += '</td><td>'
										+ vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].ligneContrat.serviceVendu.libelle;
							}
							content += '</td><td>'
									+ vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].ligneContrat.serviceVendu.typeService.libelle;

							if ("A_PAYER_SUR_CONSO_EAU" == vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].ligneContrat.serviceVendu.typePaiement) {
								content += '</td><td>'
										+ vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].valeurCompteurReleve
										+ '</td><td>'
										+ vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].quantity;
							} else {
								content += '</td><td> </td><td> ';
							}
							content += '</td><td>'
									+ vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].montantAPayerTtc
									+ '</td><td>'
									+ vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].moneyboxAvantPaiement
									+ '</td><td>'
									+ (parseFloat(vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].montantAPayerTtc) - parseFloat(vm.collectionListe.contratCollectionLists[i].lignePaiementService[x].moneyboxAvantPaiement))
									+ '</td><td> </td><td> </td><td> </td></tr>';

						}

					}

				}
			}
			;

			content += "</table>";
			printContents = content;

			var popupWin = window.open('', '_blank');
			popupWin.document.open();

			popupWin.document
					.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" />'
							+ '<style type="text/css" media="print">@page { size: landscape; }    </style>'
							+ '<style type="text/css" > table {border-spacing: 0.5rem;border-collapse: collapse;} table, table td, table th { border: 1px solid black; }   </style>'
							+ '</head><body onload="window.print()">'
							+ printContents + '</body></html>');
			popupWin.document.close();
		}

		var onSaveSuccess = function(result) {
			$scope.$emit('wamasysApp:collectionListeUpdate', result);
			vm.isSaving = false;
			vm.disableBtnEdition =false;
			$dialog.modal('hide');
			vm.errorValidation = controleAvantValidation();
		};

		var onSaveError = function() {
			vm.isSaving = false;
			vm.disableBtnEdition =false;
			$dialog.modal('hide');
			vm.errorValidation = controleAvantValidation();
		};

		vm.saveRows = function(row) {
			//On désactive les boutons.
			$dialog.modal();
			vm.disableBtnSaveCancel =true;

			var grid = $("table[id^=jqgrid-table]");

			var ids = grid.jqGrid('getDataIDs');

			var erreur = false;
			for (var i = 0; i < ids.length; i++) {
				var success = grid.jqGrid('saveRow', ids[i]);
				erreur = erreur || (!success);

			}
			if (erreur) {
				alert('erreur dans la saisie, vérifier le tableau');
				for (var i = 0; i < ids.length; i++) {
					grid.jqGrid('editRow', ids[i]);
				}
				$dialog.modal('hide');
				return;
			}

			// TODO tester que les données sont bonnes, si elles ne le sont pas,
			// dans ce cas on affiche la ligne en rouge plus un message d'erreur
			// et on demande de confirmer la saisie.

			// Ensuite onn enregistre en appelant le WebService.

			var grid = $("table[id^=jqgrid-table]");
			var dataInGrid = grid.jqGrid('getRowData');

			for (var i = 0; i < dataInGrid.length; i++) {

				var id = dataInGrid[i].id;
				// var listTrouve =
				var keepGoing = true;
				angular.forEach(vm.collectionListe.contratCollectionLists,
								function(contratCollectionList, key1) {
									if (keepGoing) {
										angular.forEach(contratCollectionList.lignePaiementService,
														function(lignePaiementService,key2) {
															if (keepGoing) {
																if (id == lignePaiementService.idLignePaiementService) {
																	lignePaiementService.montantPayeCollecteur = dataInGrid[i].montantCollecte;
																	keepGoing = false;
																}
															}
														});
									}
								});

				/*
				 * jQuery.grep(, function(e){ return
				 * e.lignePaiementService[x].id == id; });
				 */

			}

			CollectionListe.update(vm.collectionListe, onSaveSuccess,
					onSaveError);
			jQuery("#btnSaveRows,#btnCancelRows").hide();
			vm.disableBtnSaveCancel =true;

		}

		vm.cancelEditRows = function(row) {

			jQuery("#btnSaveRows,#btnCancelRows").hide();
			vm.disableBtnEdition =false;

			var grid = $("table[id^=jqgrid-table]");

			var ids = grid.jqGrid('getDataIDs');

			for (var i = 0; i < ids.length; i++) {
				grid.jqGrid('restoreRow', ids[i]);
			}

		}
	}
})();
