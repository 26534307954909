(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ReportingCollectionListController', ReportingCollectionListController);

    ReportingCollectionListController.$inject = ['$scope', '$state', '$filter', 'ParseLinks', 'AlertService', 'ReportingCollectionList'];

    function ReportingCollectionListController ($scope, $state, $filter,  ParseLinks, AlertService, ReportingCollectionList) {
        var vm = this;
        vm.search=search;
        vm.date = {
                startDate: $state.params.startDate?new Date($state.params.startDate):null,
                endDate: $state.params.endDate?new Date($state.params.endDate):null

        };


        function onSuccess(data, headers) {
            vm.reporting = data;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }
        
        function search () {
            if ( vm.date.startDate && vm.date.endDate) {
            	ReportingCollectionList.query({
	        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
	        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")
	            }, onSuccess, onError);
            }

        }

	      
        $scope.openRangeDateTimePicker = function() {
				$('#daterange1').data('daterangepicker').toggle();
		}

        
		$scope.getTotalRatioCollecteTotal = function(clReporting) {
			var total = 0;

			if (clReporting != undefined) {
				total = ( clReporting.totalCollecteParCollecteur +clReporting.totalCorrectionPaiement) /  clReporting.totalACollecte  * 100;
			
			}
			return total;
		}
		
		$scope.getTotalRatioCollecteFacture= function(clReporting) {
			var total = 0;

			if (clReporting!= undefined) {
				total = ( clReporting.totalCollecteParCollecteur +clReporting.totalCorrectionPaiement) /  clReporting.totalFacture  * 100;
			
			}
			return total;
		}
		
	}
})();
